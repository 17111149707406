@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'JetBrains Mono';
    src: url('../../public/assets/fonts/JetBrains Mono/static/JetBrainsMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
  
* {
    font-family: 'JetBrains Mono', monospace;
}


.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-green {
    color: #26A69A;
}
.text-red {
    color: #EF5350;
}

:root {
    --logo-image: url('../../public/assets/imgs/logo-light-no-text.png');
}

/* Dark mode logo */
[data-theme="dark"] {
    --logo-image: url('../../public/assets/imgs/logo-dark-no-text.png'); 
}

.logo {
    background-image: var(--logo-image);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
  

#calendar > div {
    color: inherit;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
.scrollbar-hide {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}